import { withTranslation, WithTranslation } from "react-i18next";
import { useState, ChangeEvent, FormEvent } from "react";
import emailjs from "@emailjs/browser";
import flower from "../../assets/flower.webp";
import stars from "../../assets/stars.png";
import Map from "../../components/Map/Map";
import Footer from "../../components/Footer/Footer";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

interface FormData {
  firstName: string;
  email: string;
  message: string;
}

const Contact = ({ t }: WithTranslation) => {
  const [formData, setFormData] = useState<FormData>({
    firstName: "",
    email: "",
    message: "",
  });

  const [isFirstNameValid, setIsFirstNameValid] = useState(true);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isMessageValid, setIsMessageValid] = useState(true);
  const [isSending, setIsSending] = useState(false);

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateEmail = (email: string) => {
    const emailRegex = /^[\w-]+@([\w-]+\.)+[\w-]{2,4}$/;
    return emailRegex.test(email);
  };

  const MAX_MESSAGES = 3;
  const TIME_WINDOW_MS = 3 * 60 * 60 * 1000;

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const now = Date.now();
    const history = JSON.parse(
      localStorage.getItem("messageHistory") || "[]"
    ) as number[];

    const recentMessages = history.filter(
      (timestamp) => now - timestamp < TIME_WINDOW_MS
    );

    if (recentMessages.length >= MAX_MESSAGES) {
      return;
    }

    setIsFirstNameValid(true);
    setIsEmailValid(true);
    setIsMessageValid(true);

    let isFormValid = true;

    if (formData.firstName === "") {
      setIsFirstNameValid(false);
      isFormValid = false;
    }

    if (formData.email === "" || !validateEmail(formData.email)) {
      setIsEmailValid(false);
      isFormValid = false;
    }

    if (formData.message === "") {
      setIsMessageValid(false);
      isFormValid = false;
    }

    if (!isFormValid) return;

    setIsSending(true);

    try {
      await emailjs.send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID!,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID!,
        {
          firstName: formData.firstName,
          email: formData.email,
          message: formData.message,
        },
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY!
      );

      toast.success(t("message_sent"), { autoClose: 3000 });
      setFormData({ firstName: "", email: "", message: "" });

      localStorage.setItem(
        "messageHistory",
        JSON.stringify([...recentMessages, now])
      );
    } catch (error) {
      console.log(error);
      toast.error(t("error_sending_message"), { autoClose: 3000 });
    }

    setIsSending(false);
  };

  return (
    <div className="contact">
      <ToastContainer />
      <div className="contact__figure">
        <LazyLoadImage
          className="contact__image"
          width="100%"
          height="100%"
          src={flower}
          alt="flower"
          effect="blur"
        />
        <h1 className="contact__title">{t("contact")}</h1>
      </div>
      <div className="contact__main">
        <div className="contact__wrapper">
          <img className="contact__stars" src={stars} alt="stars" />
          <div className="contact__info">
            <p className="contact__name">vesselhost@vesselhost.com</p>
            <p className="contact__name">Wajdeloty 27/1A</p>
            <p className="contact__name">Gdańsk, 80-437</p>
            <p className="contact__name">{t("phone")} 516708357</p>
          </div>
          <form className="contact__form" onSubmit={handleSubmit}>
            <div className="contact__box">
              <label className="contact__label">{t("name")}</label>
              <input
                className={
                  isFirstNameValid ? "contact__value" : "contact__valueTwo"
                }
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                maxLength={50}
              />
            </div>

            <div className="contact__box">
              <label className="contact__label">Email:</label>
              <input
                className={
                  isEmailValid ? "contact__value" : "contact__valueTwo"
                }
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                maxLength={50}
              />
            </div>

            <div className="contact__box">
              <label className="contact__label">{t("message")}</label>
              <textarea
                className={
                  isMessageValid
                    ? "contact__valueArea"
                    : "contact__valueTwoArea"
                }
                name="message"
                value={formData.message}
                onChange={handleChange}
                maxLength={1000}
              />
            </div>

            <div className="contact__buttonWrapper">
              <button
                className={isSending ? "contact__buttonOff" : "contact__button"}
                type="submit"
                disabled={isSending}
              >
                {isSending ? t("sending") : t("send")}
              </button>
            </div>
          </form>
        </div>
      </div>
      <Map />
      <Footer />
    </div>
  );
};

export default withTranslation()(Contact);
