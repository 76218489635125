import facebookIcon from "../../assets/facebook.png";
import instagramIcon from "../../assets/instagram.png";
import { withTranslation, WithTranslation } from "react-i18next";

const Footer = ({t}: WithTranslation) => {
  return (
    <footer className="footer">
      <div className="footer__main">
        <div className="footer__wrapper">
          <div className="footer__box">
            <p className="footer__text">Wajdeloty 27/1A</p>
            <p className="footer__text">Gdańsk, 80-437</p>
          </div>
          <div className="footer__box">
            <p className="footer__text">vesselhost@vesselhost.com</p>
            <p className="footer__text">{t("phone")} 516708357</p>
          </div>
        </div>
        <div className="footer__wrapperTwo">
          <div className="footer__boxTwo">
            <div className="footer__link">
              <img className="footer__icon" src={facebookIcon} alt="facebook" />
            </div>
            <div className="footer__link">
              <img
                className="footer__icon"
                src={instagramIcon}
                alt="instagram"
              />
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default withTranslation()(Footer);
